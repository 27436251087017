import {Controller} from '@hotwired/stimulus'

import {TutorizeClassicEditor} from 'ckeditor5'
import 'ckeditor5/build/translations/de'
import {TutorizeUploadAdapterPlugin} from '../components/ckeditor/upload_adapter'
import {debounce} from 'debounce'
import {mentionItemRenderer, MentionCustomization} from '../components/ckeditor/mentions'

export default class extends Controller {
    static values = {
        mediaFileUploadEnabled: Boolean,
        mediaFileUploadEndpoint: String,
        mediaFileUploadAllowedFileTypes: String,
        mediaFileUploadMaxFileSize: Number,
        mediaFileUploadMediaPoolUrl: String,
        mediaFileUploadTooltipText: String,
        htmlAllowedTags: String,
        htmlAllowedAttrs: String,
        height: Number,
        maxHeight: Number,
        tablesEnabled: Boolean,
        mediaEmbedEnabled: Boolean,
        required: Boolean,
        maxlength: Number,
        minlength: Number,
        requiredMsg: String,
        minlengthMsg: String,
        maxlengthMsg: String,
        bubbleChangeDataEvent: Boolean,
        mentionUrl: String
    }

    initialize() {
        this._handleChangeDataEvent = debounce(this._handleChangeDataEvent.bind(this), 500)
        this._handleValidationError = debounce(this._handleValidationError.bind(this), 500)
        super.initialize()
    }

    connect() {
        TutorizeClassicEditor
            .create(this.element, this._editorOptions)
            .then(editor => (this._setupEditor(editor)))

        this.element['editor'] = {
            setBackgroundImage: this.setBackgroundImage.bind(this)
        }
    }

    disconnect() {
        this.editor.destroy()
    }

    setBackgroundImage(backgroundImageUrl, opacity) {
        opacity = typeof opacity === 'undefined' ? 0 : opacity
        this.editor.editing.view.change(writer => {
            const editorDocumentRoot = this.editor.editing.view.document.getRoot()
            writer.setStyle('background-color', `rgba(255, 255, 255, ${opacity}) !important`, editorDocumentRoot)
            const main = this.element.parentElement.querySelector('.ck-editor__background_image_wrapper')
            if (!main) return

            if (backgroundImageUrl && backgroundImageUrl.length > 0) {
                main.style.backgroundImage = `url("${backgroundImageUrl}")`
            } else {
                main.style.backgroundImage = ''
            }
        })
    }

    get _editorOptions() {
        return {
            language: document.documentElement.lang,
            extraPlugins: this._extraPlugins,
            removePlugins: this._removePlugins,
            mediaFileUpload: this._mediaFileUploadOptions,
            mediaFileEmbed: this._mediaFileEmbedOptions,
            mention: this._mentionOptions,
            toolbar: {
                shouldNotGroupWhenFull: true,
                items: this._enabledToolbarItems
            },
            ui: {
                viewportOffset: {
                    top: 54
                }
            }
        }
    }

    _setupEditor(editor) {
        this.editor = editor
        this._setHeight()
        this._setupWordCount()
        this._setupValidations()
        this._setupChangeDataEvent()
    }

    _setHeight() {
        const height = this.heightValue || 500
        const maxHeight = this.maxHeightValue || 800
        this.editor.editing.view.change(writer => {
            const editorDocumentRoot = this.editor.editing.view.document.getRoot()
            writer.setStyle('min-height', `${height}px`, editorDocumentRoot)
            writer.setStyle('max-height', `${maxHeight}px`, editorDocumentRoot)
        })
    }

    _setupWordCount() {
        const wordCountPlugin = this.editor.plugins.get('WordCount')
        if (!wordCountPlugin) return

        const parent = this.element.parentElement
        const editor_wrapper = parent.querySelector('div.ck.ck-editor')
        if (!editor_wrapper) return

        this.element.parentElement.insertBefore(wordCountPlugin.wordCountContainer, editor_wrapper.nextSibling)
    }

    get _shouldValidate() {
        return !!this.maxlengthValue || !!this.minlengthValue || !!this.required
    }

    _setupValidations() {
        if (!this._shouldValidate) return

        this._validationInput = this._buildValidationInput()
        const container = this.editor.plugins.get('WordCount').wordCountContainer
        container.appendChild(this._validationInput)

        this.element.form.addEventListener('submit', e => {
            if (!this._validateError()) {
                e.preventDefault()
                e.stopPropagation()
                return false
            } else {
                this._validationInput.remove()
            }
        })
    }

    _handleValidationError(_event) {
        this._validateError()
    }

    _validateError() {
        const dataLength = this.editor.getData().length

        if (this.requiredValue && dataLength === 0) {
            this._validationInput.setCustomValidity(this.requiredMsgValue)
            this._validationInput.reportValidity()
            return false
        } else if (this.maxlengthValue && dataLength > this.maxlengthValue) {
            this._validationInput.setCustomValidity(this.maxlengthMsgValue)
            this._validationInput.reportValidity()
            return false
        } else if (this.minlengthValue && dataLength < this.minlengthValue) {
            this._validationInput.setCustomValidity(this.minlengthMsgValue)
            this._validationInput.reportValidity()
            return false
        } else {
            this._validationInput.setCustomValidity('')
            return true
        }
    }

    _setupChangeDataEvent() {
        this.editor.model.document.on('change:data', (event) => {
            if (this._shouldValidate) this._handleValidationError(event)
            if (this.bubbleChangeDataEventValue === true) this._handleChangeDataEvent(event)

            this.element.form.dispatchEvent(new CustomEvent('change'))
        })
    }

    _handleChangeDataEvent(_event) {
        this.element.dispatchEvent(
            new CustomEvent('editor.change:data', {
                bubbles: true,
                detail: {
                    data: this.editor.getData()
                }
            })
        )
    }

    _buildValidationInput() {
        const name = `${this.element.getAttribute('name')}_validation`
        const validationInput = document.createElement('input')
        validationInput.setAttribute('type', 'text')
        validationInput.setAttribute('tabindex', '-1')
        validationInput.setAttribute('name', name)
        validationInput.classList.add('visually-hidden')

        validationInput.addEventListener('focus', () => this.editor.editing.view.focus())

        return validationInput
    }

    _insertFile(e) {
        const file = e.detail

        if (['document', 'video'].includes(file.type)) {
            this.editor.model.change(writer => {
                const mediaFileEmbed = writer.createElement('mediaFileEmbed', {
                    'data-media-file-json': JSON.stringify(file),
                })
                this.editor.model.insertContent(mediaFileEmbed, this.editor.model.document.selection)
            })
        } else if (file.type === 'image') {
            this.editor.model.change(writer => {
                const element = writer.createElement('imageBlock', {src: file.download_url})
                this.editor.model.insertContent(element, this.editor.model.document.selection)
            })
        } else {
            this.editor.model.change(writer => {
                const element = writer.createText(file.name)
                writer.setAttribute('linkHref', file.download_url, element)
                this.editor.model.insertContent(element, this.editor.model.document.selection)
            })
        }
    }

    get _mediaFileUploadOptions() {
        if (!this._fileUploadEnabled) return {}

        const directUploads = buildDirectUploadsElement({
            endpoint: this.mediaFileUploadEndpointValue,
            allowedFileTypes: this.mediaFileUploadAllowedFileTypesValue,
            maxFileSize: this.mediaFileUploadMaxFileSizeValue,
            mediaPoolUrl: this.mediaFileUploadMediaPoolUrlValue,
            tooltipText: this.mediaFileUploadTooltipTextValue,
            imageUrl: this.mediaFileUploadImageUrlValue
        })

        directUploads.addEventListener('fileUploaded', e => this._insertFile(e))

        return {directUploads}
    }

    get _mediaFileEmbedOptions() {
        if (!this._fileUploadEnabled) return {}

        return {
            editingDowncast: mediaFileEmbedEditingDowncast
        }
    }

    get _mentionOptions() {
        if (!this._mentionsEnabled) return {}

        return {
            // dropdownLimit: 4,
            feeds: [
                {
                    marker: '@',
                    feed: query => {
                        const url = this.mentionUrlValue + '?q=' + query
                        return fetch(url).then(r => r.json()).then(json => json.results)
                    },
                    itemRenderer: mentionItemRenderer
                }
            ]
        }
    }

    get _mentionsEnabled() {
        return this.hasMentionUrlValue
    }

    get _fileUploadEnabled() {
        return !!this.mediaFileUploadEnabledValue
    }

    get _tablesEnabled() {
        return !!this.tablesEnabledValue
    }

    get _mediaEmbedEnabled() {
        return !!this.mediaEmbedEnabledValue
    }

    get _extraPlugins() {
        const plugins = []

        if (this._fileUploadEnabled) {
            const url = this.mediaFileUploadEndpointValue
            const tutorizeUpload = function (editor) {
                return TutorizeUploadAdapterPlugin(editor, url)
            }
            plugins.push(tutorizeUpload)
        }

        if (this._mentionsEnabled) {
            plugins.push(MentionCustomization)
        }

        return plugins
    }

    get _removePlugins() {
        let removePlugins = []

        if (!this._tablesEnabled) {
            removePlugins.push('Table')
            removePlugins.push('TableToolbar')
            removePlugins.push('TableProperties')
            removePlugins.push('TableCellProperties')
        }

        if (!this._fileUploadEnabled) {
            removePlugins.push('Image')
            removePlugins.push('ImageBlock')
            removePlugins.push('ImageCaption')
            removePlugins.push('ImageInline')
            removePlugins.push('ImageResize')
            removePlugins.push('ImageStyle')
            removePlugins.push('ImageToolbar')
            removePlugins.push('ImageUpload')
            removePlugins.push('MediaEmbed')
            removePlugins.push('TutorizeMediaUpload')
        }

        if (!this._mediaEmbedEnabled) {
            removePlugins.push('MediaEmbed')
        }

        return removePlugins
    }

    get _enabledToolbarItems() {
        let items = [
            'heading',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            '|',
            'alignment',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'link',
            'removeFormat',
            'undo',
            'redo'
        ]

        if (this._fileUploadEnabled) items.splice(17, 0, 'tutorizeMediaUpload')
        if (this._tablesEnabled) items.splice(17, 0, 'insertTable')

        return items
    }
}

function buildDirectUploadsElement(opts) {
    const directUploadsWrapper = document.createElement('div')
    directUploadsWrapper.setAttribute('data-controller', 'direct-upload')
    directUploadsWrapper.setAttribute('data-direct-upload-target', 'dragDrop')
    directUploadsWrapper.setAttribute('data-direct-upload-endpoint', opts.endpoint)
    directUploadsWrapper.setAttribute('data-direct-upload-allowed-file-types', opts.allowedFileTypes)
    directUploadsWrapper.setAttribute('data-direct-upload-max-file-size', opts.maxFileSize)
    directUploadsWrapper.setAttribute('data-direct-upload-media-pool-url', opts.mediaPoolUrl)
    directUploadsWrapper.setAttribute('data-direct-upload-tooltip-text', opts.tooltipText)
    directUploadsWrapper.classList.add('uppy-DragDrop-container')
    directUploadsWrapper.classList.add('uppy-DragDrop--is-dragdrop-supported')

    const fileInputWrapper = document.createElement('div')
    fileInputWrapper.setAttribute('data-direct-upload-target', 'fileInput')
    fileInputWrapper.classList.add('inline-block')
    directUploadsWrapper.appendChild(fileInputWrapper)

    const mediaFileIdInput = document.createElement('input')
    mediaFileIdInput.setAttribute('data-direct-upload-target', 'fieldFileID')
    mediaFileIdInput.setAttribute('type', 'hidden')
    mediaFileIdInput.setAttribute('name', 'media_file_id_input')
    directUploadsWrapper.appendChild(mediaFileIdInput)

    const statusBar = document.createElement('div')
    statusBar.setAttribute('data-direct-upload-target', 'statusBar')
    directUploadsWrapper.appendChild(statusBar)

    return directUploadsWrapper
}

const mediaFileEmbedEditingDowncast = (modelElement, writer) => {
    const raw = modelElement.getAttribute('data-media-file-json')
    const json = JSON.parse(raw)

    const label = writer.createText(`${json.file_name} (${json.type_translation})`)
    const labelWrapper = writer.createContainerElement('div', {class: 'bg-gray-200 p-2'}, [label])
    let children = [labelWrapper]

    switch (json.type) {
        case 'video':
            const sources = json.video.filter(src => (src.endsWith('.mp4'))).map(src => (writer.createEmptyElement('source', {src})))
            const video = writer.createContainerElement('video', {
                style: "width: 100%; height: 100%"
            }, sources)
            children.push(video)
            break
        case 'document':
            let data = json.document[0]
            const obj = writer.createEmptyElement('object', {
                data,
                type: 'application/pdf',
                style: 'width:100%; height:60vh;'
            })
            children.push(obj)
            break
        default:
            break
    }

    const div = writer.createContainerElement('div', {}, children)
    const figure = writer.createContainerElement('figure', {class: 'media ck-widget'}, [div])
    return figure
}
