import {Controller} from '@hotwired/stimulus'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import StatusBar from '@uppy/status-bar'
import TutDragDrop from '../components/uppy/tut-drag-drop'

import German from '../components/uppy/locales/de_DE'
import English from '../components/uppy/locales/en_US'
import {toast} from '../components/utility/toast'

// Neat trick for generating "unique" ids for stimulus controller instances, @see initialize().
let id = 0

/*
    Targets:
        statusBarTarget
            Shows a upload progress bar at this location. Optional.
        dragDropTarget
            Define the drag&drop area. Optional.
        fieldFileIDTarget
            Set this element for storing media files ids. Optional.
        fileNameTarget
            Define a target for displaying media file names after a successful upload.
        fileInputTarget
            Define a target area to render the hidden file input. Required.
        searchFormTarget
            Can be set on a different form target within the DOM controlled by this controller. If set, the form submit
            will be triggered. This is used for submitting the search form of the media pool modal dialog to "reset" the
            search results after a successful upload.

    Dataset:
        data-direct-upload-max-file-size
            Define the maximum file size of uploadable files in bytes. Default: 2 Gigabytes
        data-direct-upload-max-number-of-files
            Define the maximum number files. Default: 1
        data-direct-upload-min-number-of-files
            Define the minimum number files. Default: 1
        data-direct-upload-allowed-file-types
            Define the allowed filetypes. Default: '*'
        data-direct-upload-endpoint
            Set upload URL. Default: ''
        data-direct-upload-field-name
            Define the field name for uploading files. Default: 'media_file[media]'
        data-direct-upload-submit-closest-form
            (default false) Submit closest surrounding form after a successful upload.
        data-direct-upload-tooltip-text
            This shows a hoverable tooltip information icon after the file input information and links. Default: ''
        data-direct-upload-media-pool-url
            Define a url for the media pool. If set, show an additional Link for opening a modal dialog with a list if
            files. Default: ''
*/
export default class extends Controller {
    static targets = ['statusBar', 'dragDrop', 'fieldFileID', 'fileName', 'fileInput', 'searchForm']

    TWO_GIGABYTES = 2147483648

    initialize() {
        this.data.set('id', id++)
        this.uppy()
        this._handleMediaPoolClick = this._handleMediaPoolClick.bind(this)
        this._handleMediaFileClick = this._handleMediaFileClick.bind(this)
    }

    connect() {
        this._installMediaPoolClickListener()
        this._installMediaFileClickListener()
    }

    disconnect() {
        this._uninstallMediaPoolClickHandler()
        this._uninstallMediaFileClickListener()
    }

    uppy() {
        if (this._uppy === undefined) {
            let locale = German
            if (document.documentElement.lang === 'en') locale = English

            const options = this._options(locale)

            this._uppy = new Uppy(options.Core)
                .use(TutDragDrop, options.TutDragDrop)
                .use(XHRUpload, options.XHRUpload)
                .use(StatusBar, options.StatusBar)
                .on('error', this._onError)
                .on('upload-error', this._onUploadError)
                .on('upload-success', this._onUploadSuccess)
                .on('restriction-failed', this._onRestrictionFailed)
                .on('complete', this._onComplete)
        }

        return this._uppy
    }

    removeFileAction(event) {
        this.uppy().log('[DirectUploads] remove file action called')
        if (!this._canAcceptMultipleFiles()) return

        let element = event.currentTarget
        let mediaFileId = element.dataset.mediaFileId

        if (element && mediaFileId) {
            let existingMediaFileIds = JSON.parse(this.fieldFileIDTarget.value)
            existingMediaFileIds.splice(existingMediaFileIds.indexOf(parseInt(mediaFileId)), 1)
            this.fieldFileIDTarget.value = JSON.stringify(existingMediaFileIds)
            let listElement = element.parentNode
            listElement.parentNode.removeChild(listElement)
        }
    }

    _onUploadSuccess = (file, response) => {
        this.uppy().log('[DirectUploads] onUploadSuccess')
        if (response.body.growl_message) toast(response.body.growl_message, 'success')

        if (this.hasFieldFileIDTarget) {
            let mediaFileId = response.body.id
            if (this._canAcceptMultipleFiles()) {
                let existingMediaFileIds = JSON.parse(this.fieldFileIDTarget.value)
                if (existingMediaFileIds.length <= 0) this.fileNameTarget.innerHTML = ''
                if (existingMediaFileIds.indexOf(mediaFileId) === -1) {
                    // existingMediaFileIds list does not include newly uploaded file.
                    existingMediaFileIds.push(mediaFileId)
                    let uniqMediaFileIDs = [...new Set(existingMediaFileIds)]
                    this.fieldFileIDTarget.value = JSON.stringify(uniqMediaFileIDs)
                    this.fileNameTarget.insertAdjacentHTML('beforeend', `
                        <li>
                            ${file.name}
                            <button name="button" type="button" class="btn btn-link" 
                            data-action="click->direct-upload#removeFileAction" data-media-file-id="${mediaFileId}">
                                <i class="fas fa-times-circle fa-fw"></i>
                            </button>
                        </li>
                    `)
                }
            } else {
                if (this.hasFileNameTarget) this.fileNameTarget.innerText = file.name
                if (this.hasFieldFileIDTarget) this.fieldFileIDTarget.value = mediaFileId
                this._triggerFileUploaded(response.body)
            }
        }

        this._submitClosestForm()
    }

    _onUploadError = (file, error, response) => {
        this.uppy().log('[DirectUploads] onUploadError')
        this.uppy().log(`[DirectUploads] Upload error: ${error.stack}`, 'warning')
        if (response.body.error) {
            this.uppy().log(`[DirectUploads] Upload error response: ${response.body.error}`)
            toast(response.body.error, 'alert')
        }
    }

    _onError = (error) => {
        this.uppy().log('[DirectUploads] onError')
        this.uppy().log(`[DirectUploads] Uppy error: ${error.stack}`, 'warning')
    }

    _onComplete = (result) => {
        this.uppy().log('[DirectUploads] onComplete')

        this.uppy().cancelAll()
        let firstSuccessfulFileName = typeof result.successful[0] != 'undefined' ? result.successful[0].name : null
        if (firstSuccessfulFileName) {
            this._submitSearchFormTarget(firstSuccessfulFileName)
        } else {
            this._submitSearchFormTarget('')
        }
        this._clearHeartbeatInterval()
    }

    _onRestrictionFailed = (file, error) => {
        this.uppy().log('[DirectUploads] onRestrictionFailed')
        this.uppy().log(`[DirectUploads] Restriction errors: ${error}`)
    }

    _onBeforeUpload = (_files) => {
        this.uppy().log('[DirectUploads] onBeforeUpload')
        this._setHeartbeatInterval()
    }

    _onBeforeFileAdded = (currentFile, files) => {
        this.uppy().log('[DirectUploads] onBeforeFileAdded')
        const {maxFileSize, maxNumberOfFiles, allowedFileTypes} = this.uppy().opts.restrictions

        if (maxNumberOfFiles) {
            if (Object.keys(files).length + 1 > maxNumberOfFiles) {
                toast(this.uppy().i18n('youCanOnlyUploadX', {smart_count: maxNumberOfFiles}), 'alert')
            }
        }

        if (allowedFileTypes) {
            const isCorrectFileType = allowedFileTypes.some((type) => {
                // if this is a mime-type
                if (type.indexOf('/') > -1) {
                    if (!currentFile.type) return false
                    return currentFile.type.replace(/;.*?$/, '').match(type)
                }

                // otherwise this is likely an extension
                if (type[0] === '.') {
                    const extension = currentFile.name.slice(currentFile.name.lastIndexOf('.') + 1)
                    return extension.toLowerCase() === type.substr(1).toLowerCase()
                }
                return false
            })

            if (!isCorrectFileType) {
                toast(this.uppy().i18n('fileTypeNotAllowed'), 'alert')
            }
        }

        // We can't check maxFileSize if the size is unknown.
        if (maxFileSize && currentFile.data.size != null) {
            if (currentFile.data.size > maxFileSize) {
                toast(this.uppy().i18n('exceedsSize'), 'alert')
            }
        }
    }

    _options(locale) {
        return {
            Core: {
                debug: true,
                autoProceed: true,
                allowMultipleUploads: false,
                locale: locale,
                restrictions: {
                    maxFileSize: parseInt(this.data.get('maxFileSize')) || this.TWO_GIGABYTES,
                    maxNumberOfFiles: this.data.get('maxNumberOfFiles') || 1,
                    minNumberOfFiles: this.data.get('minNumberOfFiles') || 1,
                    allowedFileTypes: this.data.has('allowedFileTypes') ? this.data.get('allowedFileTypes').split(',') : ''
                },
                onBeforeFileAdded: this._onBeforeFileAdded,
                onBeforeUpload: this._onBeforeUpload
            },
            TutDragDrop: {
                target: this.dragDropTarget,
                inputFieldTarget: this.fileInputTarget,
                tooltipText: this.data.get('tooltipText'),
                mediaPoolUrl: this.data.get('mediaPoolUrl')
            },
            XHRUpload: {
                endpoint: this.data.get('endpoint'),
                formData: true,
                fieldName: this.data.get('fieldName') || 'media_file[media]',
                headers: {
                    'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                    'Accept': 'application/json'
                },
                limit: 10, // default 5
                timeout: 60000 // default 30000
            },
            StatusBar: {
                target: this.statusBarTarget,
                showProgressDetails: true,
                hidePauseResumeButton: true,
                hideUploadButton: true
            }
        }
    }

    _canAcceptMultipleFiles() {
        return (this.data.has('maxNumberOfFiles') && this.data.get('maxNumberOfFiles') !== '1')
    }

    _submitClosestForm() {
        this.uppy().log('[DirectUploads] fire rails form submit on closest form')
        if (this.data.has('submitClosestForm') && this.data.get('submitClosestForm').toLowerCase() === 'true') {
            const form = this.element.closest('form')
            Rails.fire(form, 'submit')

            if (form.dataset.remote !== 'true') form.submit()
        }
    }

    _submitSearchFormTarget(searchFormText) {
        this.uppy().log('[DirectUploads] fire rails form submit on search form target')
        if (!this.hasSearchFormTarget) return

        let searchFormSearchInput = this.searchFormTarget.querySelector('input[type=search]')
        if (searchFormSearchInput) searchFormSearchInput.value = searchFormText
        Rails.fire(this.searchFormTarget, 'submit_datatable')
    }

    _installMediaPoolClickListener() {
        this.uppy().log('[DirectUploads] install media pool listener')
        if (!this.data.has('mediaPoolUrl')) return

        this.fileInputTarget.querySelector('[data-controller="modal-link"]')
            .addEventListener('click', this._handleMediaPoolClick)
    }

    _uninstallMediaPoolClickHandler() {
        this.uppy().log('[DirectUploads] uninstall media pool listener')
        if (!this.data.has('mediaPoolUrl')) return

        this.fileInputTarget.querySelector('[data-controller="modal-link"]')
            .removeEventListener('click', this._handleMediaPoolClick)
    }

    _handleMediaPoolClick() {
        document.getElementById('modalHolder').dataset.id = this.data.get('id')
        this._triggerMediaPoolOpened()
    }

    _installMediaFileClickListener() {
        this.uppy().log('[DirectUploads] install media file listener')
        if (!this.data.has('mediaPoolUrl')) return

        document.addEventListener('click', this._handleMediaFileClick)
    }

    _uninstallMediaFileClickListener() {
        this.uppy().log('[DirectUploads] uninstall media file listener')
        if (!this.data.has('mediaPoolUrl')) return

        document.removeEventListener('click', this._handleMediaFileClick)
    }

    _handleMediaFileClick(event) {
        const element = event.target
        const modalHolder = document.getElementById('modalHolder')

        if (modalHolder.dataset.id !== this.data.get('id')) return
        if (!element.classList.contains('choose_media_file')) return

        const mediaFileData = JSON.parse(element.dataset.mediaFileJson)

        if (this.hasFileNameTarget) this.fileNameTarget.innerText = mediaFileData.name
        if (this.hasFieldFileIDTarget) this.fieldFileIDTarget.value = mediaFileData.id
        this._triggerFileUploaded(mediaFileData)

        this._submitClosestForm()
    }

    _triggerFileUploaded(file) {
        let event = new CustomEvent('fileUploaded', {detail: file})
        this.element.dispatchEvent(event)
    }

    _triggerMediaPoolOpened() {
        const event = new CustomEvent('mediaPoolOpened')
        this.element.dispatchEvent(event)
    }

    _setHeartbeatInterval() {
        this._heartbeatTimeout = setInterval(function () {
            fetch('/heartbeats', {
                method: 'PATCH',
                credentials: 'same-origin',
                headers: {
                    'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                    'Accept': 'application/json'
                }
            }).catch(error => console.log(error))
        }, 300000)
    }

    _clearHeartbeatInterval() {
        if (this._heartbeatTimeout) clearInterval(this._heartbeatTimeout)
    }
}
